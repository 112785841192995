<template>
  <div>
    <v-card>
      <v-card-title>
        <h1>{{ userInvitation.firstName }} {{ userInvitation.lastName }}</h1>
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs v-model="tab">
        <v-tab>EĞİTİM PERFORMANSI</v-tab>
        <v-tab>ÖĞRENME DENEYİMİ</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="d-flex justisy-space-around align-center pa-4">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Ara"
                single-line
                hide-details
                clearable
              />
              <v-spacer />
              <v-btn icon @click="getItems">
                <v-icon class="mr-2">refresh</v-icon>
              </v-btn>
            </div>

            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              loading-text="Yükleniyor..."
              single-expand
              show-expand
              item-key="id"
            >
              <template v-slot:[`item.progress`]="{ item }">
                <v-progress-linear :value="item.progress" height="25">
                  <template v-slot:default="{ value }">
                    <strong>%{{ Math.ceil(value) }}</strong>
                  </template>
                </v-progress-linear>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu v-if="item.progress == 100" close-on-content-click>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="getCert(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-certificate</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Sertifikayı İndir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`expanded-item`]="{ headers, item }">
                <td :colspan="headers.length" class="pa-8">
                  <creg-detail :creg="item" />
                </td>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item>
            <div class="pa-4" style="min-height: 300px;">
              <p v-if="userExperiences.length == 0" class="grey--text">
                Geçmiş öğrenme deneyimi bulunamadı.
              </p>
              <div v-else v-for="(exp, i) in userExperiences" :key="i">
                <p v-if="exp.item.type == 'video'">
                  <span class="grey--text">{{
                    exp.date.toLocaleString("tr-TR")
                  }}</span>
                  tarihinde
                  <span class="font-weight-bold">{{ exp.item.title }}</span>
                  başlıklı videoyu izledi.
                </p>
                <p v-if="exp.item.type == 'document'">
                  <span class="grey--text">{{
                    exp.date.toLocaleString("tr-TR")
                  }}</span>
                  tarihinde
                  <span class="font-weight-bold">{{ exp.item.title }}</span>
                  başlıklı belgeyi inceledi.
                </p>
                <p v-if="exp.item.type == 'quiz'">
                  <span class="grey--text">{{
                    exp.date.toLocaleString("tr-TR")
                  }}</span>
                  tarihinde
                  <span class="font-weight-bold">{{ exp.item.title }}</span
                  >'na katıldı.
                </p>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>

    <v-dialog
      v-model="dialogCert"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card color="black" flat rounded="0">
        <v-card-title class="primary white--text pr-3">
          Katılım Sertifikası Hazır!
          <v-spacer />
          <v-btn color="white" icon @click="dialogCert = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-container class="fill-height pa-0 ma-0">
          <v-row no-gutters>
            <v-col>
              <div class="cert">
                <iframe :src="certUrl" frameborder="0"></iframe>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "@/plugins/firebase";
import CregDetail from "./CregDetail.vue";

export default Vue.extend({
  components: {
    CregDetail
  },
  props: {
    userInvitation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: null,
      search: "",
      loading: false,
      headers: [
        {
          text: "Eğitim Adı",
          align: "start",
          filterable: true,
          value: "courseName"
        },
        { text: "Tamamlanan", value: "progress", width: "120px" },
        { text: "Baş. Tar.", value: "dateCreated", width: "120px" },
        { text: "Bit. Tar.", value: "dateGraduated", width: "120px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],
      items: [],
      userExperiences: [],
      dialogCert: false,
      certUrl: ""
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },

    // En son yapılan başarılı sınavın tarihini bulur
    // ve ders kaydının mezuniyet tarihini günceller.
    async findDateGraduated(cregId, uid, courseId) {
      try {
        let returnVal = null;
        const quizQuery = await firebase
          .firestore()
          .collection("quizzes")
          .where("uid", "==", uid)
          .where("courseId", "==", courseId)
          .where("status", "==", "passed")
          .orderBy("dateCreated", "desc")
          .limit(1)
          .get();

        if (!quizQuery.empty) {
          const dateCreated = quizQuery.docs[0].data().dateCreated;

          // Mezuniyet tarihini kaydet
          const cregRef = firebase
            .firestore()
            .doc(`businessCourseRegistrations/${cregId}`);

          await cregRef.update({
            dateGraduated: dateCreated
          });

          returnVal = dateCreated.toDate().toLocaleDateString("tr-TR");
        }

        return returnVal;
      } catch (err) {
        console.error(`Mezuniyet tarihi aranırken hata:  ${err.message}`);
      }
    },

    // Ders kaydının mezuniyet tarihini null yapar.
    async resetDateGraduated(cregId) {
      try {
        // Mezuniyet tarihini kaydet
        const cregRef = firebase
          .firestore()
          .doc(`businessCourseRegistrations/${cregId}`);

        await cregRef.update({
          dateGraduated: null
        });
      } catch (err) {
        console.error(`HATA: Mezuniyet tarihi sıfırlanamadı.  ${err.message}`);
      }
    },

    async getItems() {
      this.loading = true;

      try {
        this.items = [];

        // Get all courses
        const regQuery = await firebase
          .firestore()
          .collection("businessCourseRegistrations")
          .where("uid", "==", this.userInvitation.uid)
          .orderBy("dateCreated")
          .get();

        regQuery.forEach(async regDoc => {
          if (regDoc.exists) {
            const reg = regDoc.data();
            reg.id = regDoc.id;

            const newItem = {
              id: regDoc.id,
              progress: reg.progress,
              dateCreated: reg.dateCreated.toDate().toLocaleDateString("tr-TR"),
              dateGraduated: reg.dateGraduated
                ? reg.dateGraduated.toDate().toLocaleDateString("tr-TR")
                : null,
              isActive: reg.isActive,
              courseId: reg.courseId,
              completedLessons: reg.completedLessons,
              completedLessonItems: reg.completedLessonItems,
              uid: reg.uid
            };

            // Eğer eğitim tamamlanmış ama
            // mezuniyet tarihi kayıtlı değil ise
            // en son yapılan başarılı sınavın tarihini
            // mezuniyet tarihi olarak kullan.
            if (reg.progress === 100) {
              newItem.dateGraduated = await this.findDateGraduated(
                regDoc.id,
                reg.uid,
                reg.courseId
              );
            } else {
              await this.resetDateGraduated(regDoc.id);
            }

            // Dersin adını al
            const courseDoc = await firebase
              .firestore()
              .doc("courses/" + reg.courseId)
              .get();

            if (courseDoc.exists) {
              newItem.courseName = courseDoc.data().name;
            }

            this.items.push(newItem);
          }
        });

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$notify({
          type: "error",
          text: `Eğitim listesi alınamadı. ${err.code} ${err.message}`,
          duration: -1
        });

        console.log(
          `HATA: Eğitim listesi alınamadı. ${err.code} ${err.message}`
        );
      }
    },

    async getLastUserExpriences() {
      try {
        // Logları yükle
        const logQuery = await firebase
          .firestore()
          .collection("lessonLogs")
          .where("user.uid", "==", this.userInvitation.uid)
          .orderBy("date", "desc")
          .get();

        logQuery.forEach(async log => {
          this.userExperiences.push({
            date: log.data().date.toDate(),
            user: log.data().user,
            route: log.data().route,
            item: log.data().item
          });
        });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Öğrenme deneyimleri yüklenemedi."
        });

        console.error(`Hata: Öğrenme deneyimleri yüklenemedi. ${err.message}`);
      }
    },

    async getCertUrl(cregId) {
      return await firebase
        .storage()
        .ref(`certificates/${cregId}.pdf`)
        .getDownloadURL();
    },

    async getCert(creg) {
      // Dersi tamamlamamışsa kullanıcıyı bilgilendir
      if (creg.progress < 100) {
        this.$notify({
          title: "Eğitim tamamlanmadı!",
          text: `Katılım sertifikası alabilmek için tüm
          modüllerin tamamlanması gereklidir.`
        });
        return;
      }

      // Sertifika adresi alınmış mı kontrol et
      if (this.certUrl) {
        this.dialogCert = true;
        return;
      }

      try {
        this.certUrl = await this.getCertUrl(creg.id);
        this.dialogCert = true;
      } catch {
        this.$notify({
          title: "Lütfen bekleyin!",
          text: "Sertifika oluşturuluyor...",
          duration: -1
        });

        // Sertifikayı oluştur
        const createCertificate = firebase
          .functions()
          .httpsCallable("certificate-createBusinessCertificate");

        await createCertificate({
          cregId: creg.id
        });
      }

      // Her 1 sn'de sertfikayı kontrol et.
      const timer = setInterval(async () => {
        try {
          this.certUrl = await this.getCertUrl(creg.id);

          if (this.certUrl) {
            this.$notify({ clean: true });
            this.dialogCert = true;

            clearInterval(timer);
          }
        } catch {
          console.error("Sertifika bulunamadı");
        }
      }, 1000);
    }
  },

  async mounted() {
    await this.getItems();
    await this.getLastUserExpriences();
  }
});
</script>

<style lang="scss" scoped>
.cert {
  height: 100%;

  iframe {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    height: calc(100% - 64px);
    border: none 0 transparent;
  }
}
</style>
