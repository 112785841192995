

















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";
import UserDetail from "./UserDetail.vue";

type businessReg = {
  id: string;
  businessId: string;
  businessCode: string;
  businessName: string;
  dateCreated: Date;
  dateExpired: Date;
  disabled: boolean;
  maxUsers: number;
  activeUsers: number;
  passiveUsers: number;
};

type userInvitation = {
  id: string;
  uid: string | null;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  roleId: string;
  dateCreated: Date;
  dateOpened: Date | null;
  isAccepted: boolean;
  businessId: string;
  businessCode: string;
  businessName: string;
  businessRegId: string;
};

export default Vue.extend({
  components: {
    Confirm,
    UserDetail
  },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "Davetiye Kodu", value: "id" },
      { text: "İsim", value: "displayName" },
      { text: "e-Posta", value: "email" },
      { text: "Davet Tarihi", value: "dateCreated", width: "160px" },
      { text: "Kabul Tarihi", value: "dateOpened", width: "160px" },
      { text: "Durum", value: "isAccepted", width: "90px" },
      { text: "", value: "actions", sortable: false, width: "50px" }
    ],
    businessReg: {} as businessReg,
    userInvitations: [] as userInvitation[],
    selectedUserInvitation: {} as userInvitation,
    dialog: false,
    dialogContent: ""
  }),
  computed: {
    selectedItemProps(): userInvitation | businessReg | {} {
      if (this.dialogContent === "UserDetail") {
        return {
          userInvitation: this.selectedUserInvitation
        };
      } else if (this.dialogContent === "EditUser") {
        return {
          userInvitation: this.selectedUserInvitation
        };
      } else if (this.dialogContent === "InviteUser") {
        return {
          businessReg: this.businessReg
        };
      } else if (this.dialogContent === "AddRemoveCourse") {
        return {
          user: {
            id: this.selectedUserInvitation.uid,
            displayName: this.selectedUserInvitation.displayName,
            email: this.selectedUserInvitation.email
          }
        };
      } else {
        return {};
      }
    },

    user(): any {
      return this.$store.getters["auth/user"];
    },

    business(): {
      id: string;
      code: string;
      name: string;
      regId: string;
    } {
      return this.$store.getters["auth/business"];
    }
  },

  methods: {
    openUser(item: userInvitation) {
      this.dialogContent = "UserDetail";
      this.selectedUserInvitation = item;
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.dialogContent = "";
      //this.selectedUserInvitation = {} as userInvitation;
    },

    async getItems() {
      this.loading = true;

      try {
        this.userInvitations = [];

        // İşyeri kaydını al
        const regDoc = await firebase
          .firestore()
          .doc("businessRegistrations/" + this.business.regId)
          .get();

        if (!regDoc.exists) throw new Error("İşyeri kaydı bulunamadı!");

        const regData = regDoc.data();

        if (regData) {
          this.businessReg = {
            id: regDoc.id,
            businessId: regData.businessId,
            businessCode: regData.businessCode,
            businessName: regData.businessName,
            dateCreated: regData.dateCreated,
            dateExpired: regData.dateExpired,
            maxUsers: regData.maxUsers,
            activeUsers: regData.activeUsers,
            passiveUsers: regData.passiveUsers,
            disabled: regData.disabled
          };
        }

        // Get all users invitations
        const invitationQs = await firebase
          .firestore()
          .collection("businessUserInvitations")
          .where("roleId", "!=", "admin")
          .where("businessRegId", "==", this.business.regId)
          .get();

        invitationQs.forEach(async invitationDoc => {
          if (invitationDoc.exists) {
            const invitationData = invitationDoc.data();
            const newInvitation: userInvitation = {
              id: invitationDoc.id,
              uid: invitationData.uid,
              firstName: invitationData.firstName,
              lastName: invitationData.lastName,
              displayName: `${invitationData.firstName} ${invitationData.lastName}`,
              email: invitationData.email,
              roleId: invitationData.roleId,
              dateCreated: invitationData.dateCreated,
              dateOpened: invitationData.dateOpened,
              isAccepted: invitationData.isAccepted,
              businessId: invitationData.businessId,
              businessCode: invitationData.businessCode,
              businessName: invitationData.businessName,
              businessRegId: invitationData.businessRegId
            };

            this.userInvitations.push(newInvitation);
          }
        });

        this.loading = false;
      } catch (e) {
        const err = e as firebase.firestore.FirestoreError;
        this.loading = false;
        this.$notify({
          type: "error",
          text: `HATA: Kullanıcı davetiyeleri alınamadı. ${err.code} ${err.message}`
        });
      }
    }
  },

  // watch: {
  //   business: async function() {
  //     await this.getItems();
  //   }
  // },

  async mounted() {
    await this.getItems();

    this.$watch("business", async function() {
      await this.getItems();
    });
  }
});
