











































































































import { Vue, Component, Prop } from "vue-property-decorator";
import firebase from "@/plugins/firebase";

interface Lesson {
  id: string;
  rowNumber: number;
  title: string;
  items: LessonItem[];
  isCompleted: boolean;
  quizzes: LessonQuiz[];
}

interface LessonItem {
  id: string;
  fileName: string;
  filePath: string;
  fileUrl: string;
  icon: string;
  rowNumber: number;
  title: string;
  type: "video" | "document";
  progress: number;
}

interface LessonQuiz {
  id: string;
  dateCreated: Date;
  score: number;
  status: "passed" | "failed";
}

@Component
export default class CregDetail extends Vue {
  @Prop({ required: true }) creg!: {
    id: string;
    progress: number;
    dateCreated: Date;
    dateGraduated: Date;
    isActive: boolean;
    courseId: string;
    completedLessonItems: {
      id: string;
      progress: number;
    }[];
    completedLessons: string[];
    uid: string;
  };

  panel = [0];

  lessons: Lesson[] = [];

  async getLessons() {
    // Modülleri al
    const lessonsQ = await firebase
      .firestore()
      .collection("lessons")
      .where("courseId", "==", this.creg.courseId)
      .orderBy("rowNumber")
      .get();

    if (!lessonsQ.empty) {
      lessonsQ.forEach(async lesson => {
        // Modül bilgileri
        const newLesson = {
          id: lesson.id,
          rowNumber: lesson.data().rowNumber,
          title: lesson.data().title,
          items: [] as LessonItem[],
          isCompleted: false,
          quizzes: [] as LessonQuiz[]
        };

        // Modüle eklenmiş öğeleri al
        const itemsQ = await firebase
          .firestore()
          .collection("lessonItems")
          .where("courseId", "==", this.creg.courseId)
          .where("lessonId", "==", lesson.id)
          .orderBy("rowNumber")
          .get();

        if (!itemsQ.empty) {
          itemsQ.forEach(async item => {
            const newItem: LessonItem = {
              id: item.id,
              fileName: item.data().fileName,
              filePath: item.data().filePath,
              fileUrl: item.data().fileUrl,
              icon: item.data().icon,
              rowNumber: item.data().rowNumber,
              title: item.data().title,
              type: item.data().type,
              progress: 0
            };

            // Öğenin kullanım bilgisini al
            if (this.creg.completedLessonItems) {
              const findResult = this.creg.completedLessonItems.find(function(
                obj
              ) {
                if (obj.id === newItem.id) {
                  return obj;
                }
              });

              if (findResult !== undefined) {
                newItem.progress = findResult.progress;
              } else {
                newItem.progress = 0;
              }
            }

            newLesson.items.push(newItem);
          });
        }

        // Modülün tamamlanma bilgisini al
        if (
          this.creg.completedLessons &&
          this.creg.completedLessons.includes(lesson.id)
        ) {
          newLesson.isCompleted = true;
        }

        // Quizleri al
        const quizQuery = await firebase
          .firestore()
          .collection("quizzes")
          .where("uid", "==", this.creg.uid)
          .where("courseId", "==", this.creg.courseId)
          .where("lessonId", "==", newLesson.id)
          .orderBy("dateCreated")
          .get();

        if (!quizQuery.empty) {
          quizQuery.forEach(quiz => {
            const newQuiz: LessonQuiz = {
              id: quiz.id,
              dateCreated: quiz.data().dateCreated.toDate(),
              score: quiz.data().score,
              status: quiz.data().status
            };

            newLesson.quizzes.push(newQuiz);
          });
        }

        this.lessons.push(newLesson);
      });
    }
  }

  async mounted() {
    await this.getLessons();
  }
}
